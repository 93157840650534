import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';

const ClassCard = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [formData, setFormData] = useState({
        studentName: "",
        grade: "",
        school: "",
        whatsapp: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // EmailJS integration
        emailjs
            .send(
                "service_cui8djd",
                "template_biop6q7",
                {
                    name: formData.studentName,
                    grade: formData.grade,
                    school: formData.school,
                    whatsapp: formData.whatsapp,
                },
                "Ev3AzNG1PEcTs5oyV"
            )
            .then(
                (result) => {
                    // Use sweetalert2 for an attractive success popup
                    Swal.fire({
                        title: 'Registration Success!',
                        text: 'Form submitted successfully! 🎉',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#3085d6',
                        background: '#f8f9fa',
                        showCloseButton: true,
                    });

                    console.log(result.text);
                    setIsFormVisible(false);
                    setFormData({ studentName: "", grade: "", school: "", whatsapp: "" });
                },
                (error) => {
                    // Use sweetalert2 for an error popup
                    Swal.fire({
                        title: 'Oops!',
                        text: 'Something went wrong. Try again!',
                        icon: 'error',
                        confirmButtonText: 'Retry',
                        confirmButtonColor: '#d33',
                        background: '#f8f9fa',
                        showCloseButton: true,
                    });
                    console.log(error.text);
                }
            );
    };

    return (
        <div
            className="relative flex flex-col items-center justify-center p-6 rounded-3xl shadow-lg bg-gradient-to-br from-blue-500 to-indigo-500 text-white w-[94%] mx-auto mt-4 transition-transform transform hover:scale-105 duration-300"
            style={{
                maxWidth: "500px",
                boxShadow: "0 10px 25px rgba(0, 0, 0, 0.3)", // Soft drop shadow
            }}
        >
            <div
                className="absolute top-0 left-0 bg-yellow-400 text-black px-4 py-1 rounded-br-3xl text-sm font-semibold shadow-md">
                New
            </div>

            {/* Title */}
            <h1 className="text-3xl font-extrabold mb-4 text-center drop-shadow-md">
                G.C.E O/L Past Paper Class
            </h1>

            {/* Subheading */}
            <p className="text-lg font-medium text-center mb-6 opacity-90">
                Series 02 - Join Now!
            </p>

            {/* Buttons */}
            <div className="flex space-x-4">
                <button
                    onClick={() => setIsFormVisible(true)}
                    className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-yellow-300 hover:text-blue-800 transition duration-300"
                >
                    Join Class
                </button>

                <a
                    href="https://chat.whatsapp.com/H1RKPODwzIpL4k6ZhWSmHn" // Replace with your WhatsApp group link
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-600 text-white px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-green-700 transition duration-300"
                >
                    Join WhatsApp Group
                </a>
            </div>

            {/* Popup Form */}
            {isFormVisible && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-all duration-200">
                    <div
                        className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-3xl shadow-2xl w-11/12 max-w-md animate-fadeIn">
                        {/* Close Button */}
                        <div className="flex justify-end">
                            <button
                                onClick={() => setIsFormVisible(false)}
                                className="text-gray-500 hover:text-red-500 transition-transform transform hover:scale-125"
                            >
                                &#x2715;
                            </button>
                        </div>

                        {/* Form Title */}
                        <h2 className="text-2xl font-bold text-center text-blue-700 drop-shadow-sm">
                            Join the Class
                        </h2>
                        <p className="text-sm text-center text-gray-600 mb-4">
                            Fill in your details to reserve your spot!
                        </p>

                        {/* Form */}
                        <form onSubmit={handleSubmit} className="space-y-3">
                            <input
                                type="text"
                                name="studentName"
                                placeholder="Student Name"
                                value={formData.studentName}
                                onChange={handleChange}
                                required
                                className="w-full p-3 rounded-full border border-gray-300 text-black focus:ring-2 focus:ring-blue-400 outline-none transition-shadow"
                            />

                            <input
                                type="text"
                                name="grade"
                                placeholder="Grade"
                                value={formData.grade}
                                onChange={handleChange}
                                required
                                className="w-full p-3 rounded-full border border-gray-300 text-black focus:ring-2 focus:ring-blue-400 outline-none transition-shadow"
                            />

                            <input
                                type="text"
                                name="school"
                                placeholder="School"
                                value={formData.school}
                                onChange={handleChange}
                                required
                                className="w-full p-3 rounded-full border border-gray-300 text-black focus:ring-2 focus:ring-blue-400 outline-none transition-shadow"
                            />

                            <input
                                type="text"
                                name="whatsapp"
                                placeholder="WhatsApp Number"
                                value={formData.whatsapp}
                                onChange={handleChange}
                                maxLength="10"
                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                required
                                className="w-full p-3 rounded-full border border-gray-300 text-black focus:ring-2 focus:ring-blue-400 outline-none transition-shadow"
                            />

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full bg-blue-600 text-white py-3 rounded-full font-bold hover:bg-blue-700 transition duration-300 transform hover:scale-105"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClassCard;
